import React,  { Suspense }  from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';

// import Home from './Home';
// import OrderStatus from './OrderStatus';
// import Logout from './Logout';
// import OrderHistory from './OrderHistory';
// import OrderDetails from './OrderDetails';


const Home = React.lazy(() => import('./Home'));
const OrderStatus = React.lazy(() => import('./OrderStatus'));
const Logout = React.lazy(() => import('./Logout'));
const OrderHistory = React.lazy(() => import('./OrderHistory'));
const OrderDetails = React.lazy(() => import('./OrderDetails'));

function App()
{ 
    return(
        <Router>
            <Switch>
                <Suspense fallback={<div style={{textAlign: "center"}}>Loading...</div>}>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/OrderStatus/*" component={OrderStatus}/>
                    <Route exact path="/Logout" component={Logout}/>
                    <Route exact path="/OrderHistory" component={OrderHistory}/>
                    <Route exact path="/OrderDetails/*" component={OrderDetails}/>
                    <Route exact path="/getOrderTrackingInfo/*" component={OrderDetails}/>
                </Suspense>
            </Switch>
        </Router>
    );
}

export default App;